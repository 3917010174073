




























import { Component, Vue } from "vue-property-decorator";
import InvoicesResource from "@/resources/invoices-resource";
import { InvoiceStatus } from "@/data/invoice";

@Component
export default class InvoicePaymentView extends Vue {
  loading: boolean = true;
  qr: string | null = null;

  invoice: InvoiceStatus | null = null;
  invoiceInterval: number | null = null;

  async created(): Promise<void> {
    await this.loadInvoice();
    await this.getQrCodeImg();
    this.invoiceInterval = setInterval(this.reloadInvoice, 5000);
  }
  beforeDestroy() {
    if (this.invoiceInterval) {
      clearInterval(this.invoiceInterval);
    }
  }

  async getQrCodeImg() {
    const invoice = this.invoice as InvoiceStatus;
    await InvoicesResource.getQrCode(invoice.paymentUrl).then(qr => {
      const reader = new FileReader();
      reader.readAsDataURL(qr);
      reader.onload = () => {
        this.qr = reader.result as string;
      };
    });
  }

  async reloadInvoice(): Promise<void> {
    const code = this.$route.params.code;

    await InvoicesResource.get(code).then(res => {
      this.invoice = new InvoiceStatus(res);
      if (this.invoice.isPaymentStarted) {
        this.$router.push({ name: "invoice-success", params: { code: code } });
      }
    });
  }

  async loadInvoice(): Promise<void> {
    this.loading = true;
    try {
      await this.reloadInvoice();
    } finally {
      this.loading = false;
    }
  }
  async tryPay(): Promise<void> {
    this.loading = true;
    try {
      await (this.invoice as InvoiceStatus).tryPay();
    } finally {
      this.loading = false;
    }
  }
}
